body#homepage {
    .section {
        h1,
        .slider-title {
            font-size: 30px;
            font-weight: 400;
            text-align: center;

            @media (max-width: $media_md) {
                font-size: 24px;
            }
        }

        .section-subtitle {
            font-size: 21px;
            font-weight: 500;
            font-style: normal;
            margin-bottom: 3rem;
        }

        .section-text {
            text-align: center;
            margin-top: 50px;

            p {
                font-weight: 300;
            }
        }

        &.slider-section {
            &.slider_v3 {
                background: url('../../../images/background/services-bg.jpg') center center;

                .slider_wrapper {
                    .slider_item {
                        .item-content {
                            .item-title {
                                color: #FFF;
                            }

                            .item-desc {
                                p {
                                    color: #FFF;
                                }
                            }
                        }
                    }
                }
            }

            &.slider_v4 {
                .comment-text {
                    p {
                        font-size: 15px;
                        font-weight: 300;
                    }
                }
            }
        }

        hr.section-separator {
            margin: 30px auto;
        }
    }

    .properties-container {
        padding-bottom: 40px;
    }

    .next-slick {
        position: absolute;
        top: 41%;
        font-size: 25px;
        right: -60px;
        border: 1px solid #bfbfbf;
        margin-left: 20px;
        padding: 0 12px;
        color: #6e6e6e;
    }

    .prev-slick {
        position: absolute;
        top: 41%;
        left: -8%;
        font-size: 25px;
        border: 1px solid #bfbfbf;
        margin-left: 20px;
        padding: 0 12px;
        color: #6e6e6e;
    }

    .section-tabs {
        padding-top: 50px;
        padding-bottom: 50px;

        .content-main {
            @media(max-width: 578px) {
                padding-left: 0;
                padding-right: 0;

                .slick-slide {
                    padding-left: 0;
                    padding-right: 0;
                }
            }
        }
    }
}

// ABOUT US section
.homepage-section-default {
    height: auto;
    align-items: center;
    display: flex;
    padding: 100px 0 100px;

    @media (max-width: 992px) {
        padding: 50px 0 0;
    }

    &.bg-alt {
        background: #f7f7f7;
    }

    @media (max-width: 1019px) {
        .img-area {
            margin-bottom: 50px;
        }
    }

    .content-area {
        h2 {
            color: color('text', 'dark');
            font-size: 34px;
            font-family: font(main);
            font-weight: 400;
            margin-bottom: 50px;
            text-align: left;

            @media (max-width: 992px) {
                font-size: 30px;
            }
        }

        p {
            color: color('text', 'light');
            font-family: font(main);
            font-weight: 400;
            font-size: 15px;
            text-align: left;
            line-height: 2;
        }
    }

    .cta-btn {
        display: inline-block;
        color: color('text', 'dark');
        font-family: font(main);
        font-weight: 400;
        font-size: 15px;
        margin-top: 50px;
        position: relative;

        @media (max-width: 992px) {
            margin-bottom: 50px;
        }

        &::after {
            content: '';
            display: block;
            box-shadow: -2px -2px 0 0px #222;
            cursor: pointer;
            transform: rotate(135deg);
            width: 13px;
            height: 13px;
            position: absolute;
            right: -15px;
            top: 5px;
            transition: all .3s;
        }

        &:hover {
            text-decoration: none;

            &::after {
                right: -20px;
            }
        }
    }
}

// OFFER YOUR REAL-ESTATE section
.text-w-bg-image-section {
    padding: 0;
    height: 670px;
    background-repeat: no-repeat;
    background-size: cover;
}

.special-offer-wrapper {
    overflow: hidden;
}

#homepage-special-offer {
    width: 1506px;
    margin-bottom: 50px;

    @media (max-width: 1266px) {
        width: auto;
    }

    .slick-slide {
        padding-left: 0;
    }
}