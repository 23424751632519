main.main-footer .footer-section.footer-main .footer-social .social-media .a2a_kit a .a2a_svg svg {
  margin: 4px;
}


body {
  .header-container {
    .header-nav-main .header-nav {
      font-weight: 400;
    }

    &.scrolled {
      .company-brand img {
        height: 54px !important;
      }
    }
  }


  .main-footer .footer-section.footer-main {
    .footer-nav ul li, .footer-contact li {
      font-weight: 400;
    }

    .footer-social .social-icons {
      .social-icon {
        &:hover {
          border-color: inherit;

          i {
            color: color('white');
          }
        }
      }
    }

    .footer-social .social-media .a2a_kit {
      a {
        .a2a_svg {
          border: 1px solid color('footer-social-findus-i', '', '', $colors-footer);
        }

        &:hover {
          .a2a_svg {
            border-color: inherit;
            opacity: 1;

            svg {
              background: color('white');

              path {
                fill: color('footer-social-findus-i', '', '', $colors-footer);
              }
            }
          }
        }
      }
    }
  }
}

