.group-view-section {
    padding: 40px 0;

    .container-fluid {
        .navi {
            &.navi-prev {
                left: 1px;
                color: color('white');
            }

            &.navi-next {
                right: 1px;
                color: color('white');
            }
        }

    }

    .container {
        .navi {
            &.navi-prev {
                left: -30px;
                color: color('white');
            }

            &.navi-next {
                right: -30px;
                color: color('white');
            }
        }

    }

    .carousel_component_wrapper {
        color: color('text', 'light');

        .group_carousel_wrapper {
            &.carousel-center {
                .slick-list {
                    margin: 0 -22px;
                }

                .slick-track {
                    margin-left: auto !important;
                    margin-right: auto !important;
                }

                .page_carousel_item {
                    margin-right: 22px;

                    &:first-of-type {
                        margin-left: 22px;
                    }
                }
            }

            .slick-list {
                padding-bottom: 25px;
            }

            .slick-track {
                margin: 0 auto;
            }

            // &[data-carousel-items="3"] {
            //     padding-left: 12.5%;
            // }

            /* SLICK NAVIGATION ARROWS */
            .navi {
                position: absolute;
                top: 50%;
                margin-top: -25px;
                z-index: 1;
                cursor: pointer;
                font-size: 30px;
                background: color('primary');
                line-height: 30px;
                width: 30px;
                height: 30px;
                border-radius: $border_radius;
                -webkit-border-radius: $border_radius;
                -moz-border-radius: $border_radius;
                -ms-border-radius: $border_radius;
                -o-border-radius: $border_radius;



                &.slick-disabled {
                    @include opacity(0.2);
                    pointer-events: none;
                    cursor: default;
                }

                i {
                    font-size: 2.142857142857143rem;
                    font-weight: 600;
                }
            }

            .navi {
                color: #ffffff;
            }

            .slick-dots {
                position: absolute;
                bottom: -70px;
            }
        }


        .group_carousel_item {
            margin: 0 22px;

            @include box-shadow(0px 12px 20px 0px rgba(0, 0, 0, 0.25));

            .carousel-item-info {
                padding: 10px 15px;
                margin-top: 10px;
            }

            .carousel-item-title {
                font-weight: 300;
                font-family: font(main);
                font-weight: 600;
                font-size: 16px;
                color: color('text', 'dark');
                text-transform: uppercase;
            }

            .carousel-link {
                &:hover {
                    text-decoration: none;
                }
            }

            .carousel-item-desc {
                margin-top: 15px;
                margin-bottom: 15px;
                font-size: 14px;
                font-family: font(sub);
                color: color('text', 'light');
                font-weight: 400;

                p {
                    font-size: 14px;
                    font-family: font(sub);
                    color: color('text', 'light');
                    font-weight: 400;
                    line-height: 24px;
                }
            }

            .carousel-item-img {
                @media (max-width: 576px) {
                    margin: 0 auto;
                }
            }

            .carousel-item-link {
                color: color('primary');
                font-size: 14px;
                font-weight: 700;
                font-family: font(sub);
                display: block;

                i {
                    font-size: 16px;
                    color: color('primary');
                    margin-right: 5px;
                }

                &:hover {
                    text-decoration: none;

                    i {
                        -webkit-animation: leftToRight 1s ease-out infinite;
                        animation: leftToRight 1s ease-out infinite;
                    }
                }
            }
        }

    }
}