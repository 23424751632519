/* Border variables */
$border_radius: 3px;

/* GLOBAL HTML TAG STYLES AND RESETS */
html {
    font-size: 14px;

    body {
        background-color: color('white');
        color: color('text', 'light');
        font-family: font(main);
        overflow-x: hidden;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        width: 100%;

        &.menu-is-open {
            position: relative;
        }
    }
}

a {
    @include transition(all 0.3s ease-in);

    &:link,
    &:visited,
    &:hover,
    &:active {
        color: inherit;
        outline: none;
    }
}

ul {
    list-style: none;
    padding: 0;
}

button {
    border: 0;
    background-color: transparent;
    color: color('text', 'dark');
    padding: 0;
    cursor: pointer;

    &:focus {
        outline: 0;
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 500;
    margin: 0;
}

p {
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.25;
}

/* utilities */
/* BOOTSTRAP OVERRIDES */
.card {
    border: 0;
    background-color: transparent;

    .card-header {
        border: 0;
        background-color: transparent;
    }

    .card-footer {
        border: 0;
        background-color: transparent;
    }
}

/* Global TEXT styling */
.page-subtitle {
    padding-top: 7vh;
    padding-bottom: 7vh;
    color: color('primary');
}

.page-text {
    font-size: 1rem;

    p {
        font-size: 1rem;
        line-height: 1.5;
        font-family: font(sub);
    }
}

/* BACKGROUND ALTERNATE CLASS */
.bg-alt {
    background-color: color('background', 'alt') !important;
    color: color(dark) !important;

    .dm-button {
        &.alternate {
            color: #FFF;
        }
    }
}

/* GLOBAL CONTACT CARD STYLES */
.contact-card {
    font-size: 15px;
    font-family: font(main);
    color: color('text', 'dark');

    .contact-title {
        font-family: font(main);
        font-size: 1.4rem;
        text-transform: uppercase;
        margin-bottom: 15px;
    }

    i {
        margin-right: 10px;
    }

    .accent {
        font-weight: 400;
        color: color('text', 'dark') !important;
    }
}

/* GLOBAL IMAGE RADIO INPUT TYPE STYLES */
/* provjeriti što sa ovim */
.dm-img-rd-wrapper {
    @include d-flex;
    flex-wrap: nowrap !important;
    justify-content: space-between;
    text-align: center;
}

.dm-img-rd {
    @include d-flex;
    @include align-items-center;
    flex-direction: column;
    margin-bottom: 3em;
    position: relative;

    [type="radio"] {
        width: 25px;
        height: 25px;
        outline: none;
        visibility: hidden;
        position: absolute;

        &:checked + label {
            &:before {
                background: color('secondary');
                border-color: color('secondary');
                box-shadow: inset 0 0 0 4px #ffffff;
            }

            img {
                opacity: 1;
            }
        }
    }

    label {
        cursor: pointer;

        .label-title {
            font-size: 3.2rem;
            font-weight: 300;
        }

        &:before {
            content: "";
            display: inline-block;
            width: 25px;
            height: 25px;
            border-radius: 50%;
            border: 1px solid #d9d9d9;
        }
    }

    img {
        width: 100%;
        max-width: 242px;
        padding: 20px 0;
        opacity: 0.8;
        transition: opacity 0.2s;
    }
}

/* GLOBAL KEYFRAMES */
@-webkit-keyframes leftToRight {
    0% {
        transform: translateX(0);
    }

    60% {
        transform: translateX(10px);
    }

    100% {
        transform: translateX(0);
    }
}

@keyframes leftToRight {
    0% {
        transform: translateX(0);
    }

    60% {
        transform: translateX(5px);
        -webkit-transform: translateX(5px);
        -moz-transform: translateX(5px);
        -ms-transform: translateX(5px);
        -o-transform: translateX(5px);
    }

    100% {
        transform: translateX(0);
    }
}

/* MEDIA QUERIES */
@media (max-width: $media_lg) {
    /*.card {
        .card-header {
            padding-right: 0;
            padding-left: 0;
        }

        .card-body {
            padding-right: 0;
            padding-left: 0;
        }
    }*/

    body {
        &.menu-is-open {
            overflow: hidden;
            max-height: 100%;
        }
    }
}

@media (max-width: $media_sm) {
    .dm-button {
        font-size: 1.3rem;
        line-height: 30px;
    }
}

@media(max-width: $media_xs) {
    .dm-img-rd-wrapper {
        flex-direction: column;
    }

    .dm-img-rd {
        img {
            padding: 20px 10px;
        }
    }
}

.alert {
    // position: absolute;
    // width: 310px;
    width: 94%;
    // top: 30%;
    // left: 50%;
    // margin-left: -130px;
    z-index: 9999;
    font-size: 16px;
    // padding: 40px;
}

.table-legend {
    position: relative;
    width: 100%;
    text-align: right;

    @media (max-width: $media_md) {
        text-align: center;
    }

    ul {
        position: relative;
        top: 100px;
        right: 20px;

        @media (max-width: $media_md) {
            right: auto;
        }

        li {
            display: inline-block;

            &:first-child {
                border-right: 1px solid #CCC;
                margin-right: 15px;
                padding-right: 15px;
            }

            > i {
                margin-right: 5px;
            }
        }
    }
}

.success-message-holder {
    font-size: 16px;
    padding: 10px;
}

.img-link {
    &:hover {
        text-decoration: none;
    }
}

.slick-arrow {
    &:hover {
        cursor: pointer;
    }
}