.section {
    &.home-carousel {
        padding-top: 0;
        padding-bottom: 0;
        max-width: 1200px;
        margin: 0 auto;
        color: color('text', 'light');

        .container-fluid {
            .navi {
                &.navi-prev {
                    left: 1px;
                    color: color('white');
                }

                &.navi-next {
                    right: 1px;
                    color: color('white');
                }
            }
        }

        .container {
            .navi {
                &.navi-prev {
                    left: -30px;
                    color: color('white');
                }

                &.navi-next {
                    right: -30px;
                    color: color('white');
                }
            }
        }

        .home-carousel-wrapper {
            &.carousel-center {
                .slick-list {
                    margin: 0 -22px;
                }

                .slick-track {
                    margin-left: auto !important;
                    margin-right: auto !important;
                }

                .home-carousel-item {
                    margin-right: 22px;

                    &:first-of-type {
                        margin-left: 22px;
                    }
                }
            }

            .slick-list {
                padding-bottom: 35px;
            }

            /* SLICK NAVIGATION ARROWS */
            .navi {
                position: absolute;
                top: 50%;
                margin-top: -25px;
                z-index: 1;
                cursor: pointer;
                font-size: 30px;
                background: color('primary');
                line-height: 30px;
                width: 30px;
                height: 30px;
                border-radius: $border_radius;
                -webkit-border-radius: $border_radius;
                -moz-border-radius: $border_radius;
                -ms-border-radius: $border_radius;
                -o-border-radius: $border_radius;

                &.slick-disabled {
                    @include opacity(0.2);
                    pointer-events: none;
                    cursor: default;
                }

                i {
                    font-size: 2.142857142857143rem;
                    font-weight: 600;
                }
            }

            .navi {
                color: #ffffff;
            }

            .slick-dots {
                position: absolute;
                bottom: -70px;
            }
        }

        .home-carousel-item {
            margin: 0 22px;
            position: relative;
            @include box-shadow(0px 12px 20px 0px rgba(0, 0, 0, 0.25));

            .carousel-item-info {
                padding: 0 30px 20px;
                // margin-top: 10px;
                position: absolute;
                bottom: 0;

                .section-separator {
                    height: 2px;
                    margin-top: 20px;
                    margin-bottom: 20px;
                    margin-left: 0;
                }
            }

            .carousel-item-title {
                font-family: font(main);
                font-weight: 700;
                font-size: 28px;
                color: color('white');
                text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.6);
            }

            .carousel-link {
                &:hover {
                    text-decoration: none;
                }
            }

            .carousel-item-desc {
                margin-top: 15px;
                margin-bottom: 15px;
                font-size: 15px;
                font-family: font(main);
                color: color('white');
                font-weight: 500;

                p {
                    font-size: 15px;
                    font-family: font(main);
                    color: color('white');
                    font-weight: 500;
                    line-height: 24px;
                    text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.9);
                }
            }

            .carousel-item-img {
                width: 100%;

                @media (max-width: 576px) {
                    margin: 0 auto;
                }
            }

            .carousel-item-link {
                color: color('primary');
                font-size: 14px;
                font-weight: 700;
                font-family: font(sub);
                display: block;

                i {
                    font-size: 16px;
                    color: color('primary');
                    margin-right: 5px;
                }

                &:hover {
                    text-decoration: none;

                    i {
                        -webkit-animation: leftToRight 1s ease-out infinite;
                        animation: leftToRight 1s ease-out infinite;
                    }
                }
            }
        }

        .section-separator {
            margin: 20px 0 !important;
        }
    }
}